.gradientBorder {
    position: relative;
}

.gradientBorder::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background:  linear-gradient(115deg, rgba(229,229,229,1) 0%, rgba(202,202,202,1) 0%, rgba(0,0,0,1) 100%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}