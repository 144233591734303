.gradient_bg {
  background: linear-gradient(
    115.03deg,
    rgba(125, 73, 150, 0.18) 17.08%,
    rgba(125, 73, 150, 0.08) 39.71%,
    rgba(125, 73, 150, 0.24) 63.92%
  );
}
.gradient_button {
  background: linear-gradient(
    91deg,
    #e3d7e8 -30.6%,
    #fff 50.38%,
    #e0d3e6 115.59%
  );
}
.swiper-container {
  display: flex;
  animation: marquee 0s linear infinite; /* Adjust the duration for speed */
  transition-timing-function: linear;

}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  display: inline-block;
  flex-shrink: 0;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Move by the width of one full cycle */
  }
}