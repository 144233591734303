@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Krona+One&family=Poppins:wght@500&family=Rubik&display=swap");

@layer base {
  body {
    @apply text-[#1E1B1E];
  }
}

@layer components {
  .form-group {
    @apply space-y-2;
  }

  .wrapper {
    @apply max-w-[1185px] mx-auto px-6 sm:px-10 py-4 xl:px-0;
  }

  .cborder {
    @apply border-2 border-red-500;
  }

  .btn {
    @apply px-[40px] py-[15px] lg:px-10 lg:py-[20px] font-krona rounded-[128px] text-[18px];
  }

  .section_heading {
    @apply font-krona text-[20px] lg:text-[32px] text-center tracking-[-1.6px] leading-[25px] lg:leading-[40px];
  }

  .lvisible {
    @apply hidden lg:block;
  }

  .mvisible {
    @apply hidden md:block;
  }

  .svisible {
    @apply hidden sm:block;
  }
}

html {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", sans-serif;
}
